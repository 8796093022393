import { loadEnvConfig } from './config';

const isProd = loadEnvConfig().name === 'production';

export const toggles = {
  worldsSharing: true,
  profilePage: !isProd,
  dynamicVersions: true,
};

export default toggles;
