// See https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
// Check here for code usage: https://codesandbox.io/s/usevisibility-hook-example-0vy1h?file=/src/useVisibility.ts

import { createRef, RefObject, useDeferredValue, useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

/**
 * Check if an element is in viewport

 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export default function useComponentVisibility<Element extends HTMLElement>(
  offset = 0,
  throttleMilliseconds = 100
): [Boolean, RefObject<Element>] {
  const [preIsVisible, setIsVisible] = useState(false);
  // Without using the deferred value, the initial value will stay false until the user scroll.
  // Using the deferred value here forces a rerender and toggle visibility to true if the component is visible.
  const isVisible = useDeferredValue(preIsVisible);
  const currentElement = createRef<Element>();

  const onScroll = throttle(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const top = currentElement.current.getBoundingClientRect().top;
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
  }, throttleMilliseconds);

  useEffect(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const top = currentElement.current.getBoundingClientRect().top;
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [currentElement, offset, onScroll]);

  return [isVisible, currentElement];
}
