import './axios-interceptors';
import axios from 'axios';
import { baseURL } from './config';

export type UserAccount = {
  email: string;
  email_verified: boolean;
  sub: string;
};

export const isLoggedIn = async (): Promise<UserAccount> => {
  return axios
    .request({
      baseURL,
      method: 'post',
      url: 'auth/isloggedin',
      withCredentials: true,
    })
    .then((response) => {
      return response.data.attributes;
    });
};

export const login = async (username: string, password: string): Promise<UserAccount> => {
  return axios
    .request({
      baseURL,
      method: 'post',
      url: 'auth/login',
      data: { username, password },
      withCredentials: true,
    })
    .then((response) => {
      const result = response.data;
      return result;
    });
};

export const logout = async () => {
  return axios.request({
    baseURL,
    method: 'post',
    url: 'auth/logout',
    data: undefined,
    withCredentials: true,
  });
};
