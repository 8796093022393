import './ProfilePage.css';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Profile } from '../../bff/profile';
import useBff from '../../bff/use-bff';
import ErrorToast from '../../components/ErrorToast';
import InfoToast from '../../components/InfoToast';

const ProfilePage = () => {
  const { getMyProfile, updateMyProfile } = useBff();

  const [profile, setProfile] = useState<Profile>();
  const [gamertag, setGamertag] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [isLoadError, setLoadError] = useState(false);
  const [showLoadError, setShowLoadError] = useState(false);

  const [isSaving, setSaving] = useState(false);
  const [showSaveSucceeded, setShowSaveSucceeded] = useState(false);
  const [showSaveError, setShowSaveError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMyProfile()
      .then((res) => {
        if (res.status === 200) {
          setProfile(res.data);
          setGamertag(res.data.gamertag);
          setLoadError(false);
        } else {
          setLoadError(true);
        }
      })
      .catch(() => {
        setLoadError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getMyProfile]);

  useEffect(() => {
    if (isLoadError) {
      setShowLoadError(true);
    } else {
      setShowLoadError(false);
    }
  }, [isLoadError]);

  const handleSave = () => {
    setSaving(true);
    updateMyProfile({ gamertag })
      .then((res) => {
        if (res.status === 200) {
          setShowSaveSucceeded(true);
        } else {
          setShowSaveError(true);
        }
      })
      .catch(() => {
        setShowSaveError(true);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <h1>My Profile</h1>
      <div className="ProfilePage-container">
        <div className="ProfilePage-input-row">
          <Form.Label className="ProfilePage-input-label">Email: </Form.Label>
          <div>{profile?.email}</div>
        </div>
        <div className="ProfilePage-input-row">
          <Form.Label htmlFor="gamertag" className="ProfilePage-input-label">
            Gamertag:{' '}
          </Form.Label>
          <Form.Control
            id="gamertag"
            value={gamertag}
            onChange={(event) => {
              setGamertag(event.target.value);
            }}
            disabled={isLoadError || isSaving}
          />
        </div>
        <div className="ProfilePage-action-row">
          <Button disabled={isLoading || isLoadError || isSaving} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>

      <ToastContainer position="top-center" className="p-3 position-fixed">
        <ErrorToast
          show={showLoadError}
          onClose={() => {
            setShowLoadError(false);
          }}
          message="Unable to load profile. Refresh the page to retry."
          autohide={false}
        />
        <InfoToast
          show={showSaveSucceeded}
          onClose={() => {
            setShowSaveSucceeded(false);
          }}
          message="Save successful."
          autohide={true}
        />
        <ErrorToast
          show={showSaveError}
          onClose={() => {
            setShowSaveError(false);
          }}
          message="Unable to save."
          autohide={false}
        />
      </ToastContainer>
    </>
  );
};

export default ProfilePage;
