import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { World } from '../../bff/worlds';
import { loadEnvConfig } from '../../config';
import useBff from '../../bff/use-bff';
import { useAuth } from '../../hooks/use-auth';
import ToastContainer from 'react-bootstrap/ToastContainer';
import ErrorToast from '../../components/ErrorToast';
import { useWorldState } from '../../hooks/use-world-state';
import WorldStateIndicator from './WorldStateIndicator';

type Props = {
  world: World;
};

const WorldItem = ({ world }: Props) => {
  const envConfig = loadEnvConfig();
  const fqdn = `${world.name}.${envConfig.domain}`;
  const [allowStart, setAllowStart] = useState(false);
  const [allowStop, setAllowStop] = useState(false);

  const [showStartWorldError, setShowStartWorldError] = useState(false);
  const [showStopWorldError, setShowStopWorldError] = useState(false);

  const navigate = useNavigate();
  const bff = useBff();
  const { user } = useAuth();

  const isOwnedByMe = user.sub === world.ownerId;

  const { worldState, ip, visibilityRef, refreshState, setWorldState } = useWorldState<HTMLDivElement>(world.name);

  useEffect(() => {
    setAllowStart(worldState === 'STOPPED' || worldState === 'RUNNING');
    setAllowStop(worldState === 'RUNNING');
  }, [worldState]);

  const handleStartButtonClicked = () => {
    setWorldState(worldState === 'RUNNING' ? 'RESTARTING' : 'STARTING');
    bff
      .startWorld(world.name)
      .then((res) => {
        if (res.status === 200) {
          // Delay the first call to refresh state because it takes a while to start a new server.
          // In the case of restarting a running one, it takes a while for the server to stop.
          // If we call this too soon, it will say RUNNING and won't refresh again.
          setTimeout(() => {
            refreshState();
          }, 20000);
        } else {
          setShowStartWorldError(true);
          refreshState();
        }
      })
      .catch(() => {
        setShowStartWorldError(true);
        refreshState();
      });
  };

  const handleStopButtonClicked = () => {
    setWorldState('STOPPING');
    bff
      .stopWorld(world.name)
      .then((res) => {
        if (res.status === 200) {
          refreshState();
        } else {
          setShowStopWorldError(true);
          refreshState();
        }
      })
      .catch(() => {
        setShowStopWorldError(true);
        refreshState();
      });
  };

  const handleEditButtonClicked = () => {
    navigate(world.name);
  };

  const ipComponent = ip ? <span>(IP: {ip})</span> : undefined;

  return (
    <div key={fqdn} className="grid flex-col mb-1 border border-gray-300" ref={visibilityRef}>
      <div className="flex bg-cyan-100 p-1 items-baseline">
        <WorldStateIndicator state={worldState} />
        <div className="text-xl font-bold ml-1">{world.name}</div>
      </div>
      <div className="p-1">
        <div className="italic">{fqdn}</div>
        <div>
          State: {worldState} {ipComponent}
        </div>
        <div>
          Edition: <span>{world.edition}</span> - <span>{world.version}</span>
        </div>
      </div>
      <div className="flex p-1 bg-stone-200 gap-2">
        <Button onClick={handleStartButtonClicked} disabled={!allowStart}>
          {worldState === 'RUNNING' ? 'RESTART' : 'START'}
        </Button>
        {isOwnedByMe ? (
          <>
            <Button onClick={handleStopButtonClicked} disabled={!allowStop}>
              STOP
            </Button>
            <Button onClick={handleEditButtonClicked}>Edit</Button>
          </>
        ) : undefined}
      </div>

      <ToastContainer position="top-center" className="p-3 position-fixed">
        <ErrorToast
          show={showStartWorldError}
          onClose={() => {
            setShowStartWorldError(false);
          }}
          message="Unable to start the world."
        />
        <ErrorToast
          show={showStopWorldError}
          onClose={() => {
            setShowStopWorldError(false);
          }}
          message="Unable to stop the world."
        />
      </ToastContainer>
    </div>
  );
};

export default WorldItem;
