import Spinner from 'react-bootstrap/Spinner';

export const WorldStateIndicator = ({ state }: { state: string }) => {
  switch (state) {
    case 'STOPPED':
      return (
        <span
          style={{
            display: 'flex',
            width: '16px',
            height: '16px',
            backgroundColor: 'lightgrey',
            borderRadius: '50%',
          }}
        ></span>
      );
    case 'STARTING':
      return <Spinner size="sm" animation="grow" variant="success" />;
    case 'RUNNING':
      return (
        <span
          style={{
            display: 'flex',
            width: '16px',
            height: '16px',
            backgroundColor: 'green',
            borderRadius: '50%',
          }}
        ></span>
      );
    case 'STOPPING':
      return <Spinner size="sm" animation="grow" variant="danger" />;
  }
  return <Spinner size="sm" animation="border" variant="secondary" />;
};

export default WorldStateIndicator;
