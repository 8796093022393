import './ServerPropertiesEdit.css';
import DropDown from '../../components/DropDown';

type Props = {
  serverPropertyName: string;
  serverProperties: any;
  options: string[];
  onChange: (name: string, value: any) => void;
};

const ServerPropertyDropDownEdit = ({ serverPropertyName, serverProperties, options, onChange }: Props) => {
  const id = `id-for-${serverPropertyName}`;

  const ButtonToRemove = ({ propName }: { propName: string }) => {
    const handleOnClick = () => {
      if (onChange) {
        onChange(propName, undefined);
      }
    };
    return (
      <button onClick={handleOnClick} className="text-blue-600 underline">
        remove
      </button>
    );
  };

  const handleChange = (newValue: string) => {
    onChange(serverPropertyName, newValue);
  };

  return serverProperties && serverProperties[serverPropertyName] ? (
    <div className="ServerPropertiesEdit-row">
      <label htmlFor={id} className="ServerPropertiesEdit-label">
        {serverPropertyName}
      </label>
      <div className="ServerPropertiesEdit-input">
        <DropDown
          selectElementId={id}
          options={options}
          value={serverProperties[serverPropertyName]}
          onChange={handleChange}
        />
      </div>
      <div className="ServerPropertiesEdit-action">
        <ButtonToRemove propName={serverPropertyName} />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ServerPropertyDropDownEdit;
