import axios, { AxiosRequestConfig } from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { loadEnvConfig } from '../config';

const addCorrelationId = (config: AxiosRequestConfig) => {
  const updatedConfig = {
    ...config,
    headers: {
      ...config.headers,
    },
  };
  const envConfig = loadEnvConfig();
  updatedConfig.headers[`${envConfig.userWebapp.customHeaderPrefix}-correlation-id`] = uuidV4();
  return updatedConfig;
};

axios.interceptors.request.use(addCorrelationId);
