import { useEffect, useState } from 'react';
import { World } from '../../bff/worlds';
import SignOutButton from '../../components/SignOutButton';
import WorldItem from './WorldItem';
import CreateWorld from './CreateWorld';
import useBff from '../../bff/use-bff';
import { useNavigate } from 'react-router-dom';
import toggles from '../../toggles';
import { useAuth } from '../../hooks/use-auth';
import { ToastContainer } from 'react-bootstrap';
import ErrorToast from '../../components/ErrorToast';

const WorldsPage = () => {
  const showSharings = toggles.worldsSharing;

  const [worldsOwnedByMe, setWorldsOwnedByMe] = useState<World[]>();
  const [worldsSharedWithMe, setWorldsSharedWithMe] = useState<World[]>();

  const [showListWorldsError, setShowListWorldsError] = useState(false);

  const { listWorlds } = useBff();
  const navigate = useNavigate();
  const { user } = useAuth();

  // Load all worlds once when the page first loads
  useEffect(() => {
    listWorlds({ sharedWithMe: true })
      .then((response) => {
        if (response.status === 200) {
          setWorldsOwnedByMe(response.data.filter((w) => w.ownerId === user.sub));
          setWorldsSharedWithMe(response.data.filter((w) => w.ownerId !== user.sub));
        } else {
          setShowListWorldsError(true);
        }
      })
      .catch(() => {
        setShowListWorldsError(true);
      });
  }, [listWorlds, user.sub]);

  const handleNewWorldCreated = (worldName: string) => {
    navigate(`/worlds/${worldName}`);
  };

  const worldsDisplay =
    worldsOwnedByMe && worldsOwnedByMe.length > 0 ? (
      worldsOwnedByMe.map((w: World) => (
        <div key={w.name}>
          <WorldItem world={w} />
        </div>
      ))
    ) : (
      <></>
    );

  const sharedWithMeWorlds =
    worldsSharedWithMe && worldsSharedWithMe.length > 0 ? (
      <div className="grid flex-wrap grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-1">
        {worldsSharedWithMe.map((w: World) => (
          <div key={w.name}>
            <WorldItem world={w} />
          </div>
        ))}
      </div>
    ) : (
      <div>
        🌟 Did you know you can share a world with someone, and they can share theirs with you? 'Edit' a world and add
        their Xbox gamertags to the sharing list to share it with them.
      </div>
    );

  const worldsSharedWithMeDisplay = showSharings ? (
    <div className="flex flex-col m-2">
      <h1>Worlds shared with me</h1>
      {sharedWithMeWorlds}
    </div>
  ) : undefined;

  return (
    <>
      <h1>My Worlds</h1>
      <div className="flex flex-col m-2">
        <div className="grid flex-wrap grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-1">
          {worldsDisplay}
          <div>
            <CreateWorld onNewWorldCreated={handleNewWorldCreated} />
          </div>
        </div>
      </div>
      {showSharings ? worldsSharedWithMeDisplay : undefined}
      <div className="flex flex-col m-2">
        <div>
          <SignOutButton
            onSignOutSucceed={() => {
              navigate('/signin');
            }}
          />
        </div>
      </div>

      <ToastContainer position="top-center" className="p-3 position-fixed">
        <ErrorToast
          show={showListWorldsError}
          onClose={() => {
            setShowListWorldsError(false);
          }}
          message="Unable to load the list of worlds."
          autohide={false}
        />
      </ToastContainer>
    </>
  );
};

export default WorldsPage;
