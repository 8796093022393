import axios from 'axios';
import { baseURL } from './config';

export type Profile = {
  userId: string;
  email: string;
  gamertag: string;
};

export const isUnauthorized = (err: any) => {
  return err.response?.status === 401 || err.response?.status === 403;
};

export const getMyProfile = async () => {
  return await axios
    .request<Profile>({
      baseURL,
      method: 'get',
      url: 'profile',
      withCredentials: true,
    })
    .then((response) => {
      return response;
    });
};

export const updateMyProfile = async ({ gamertag }: { gamertag: string }) => {
  return await axios
    .request<Profile>({
      baseURL,
      method: 'patch',
      url: 'profile',
      data: { gamertag },
      withCredentials: true,
    })
    .then((response) => {
      return response;
    });
};
