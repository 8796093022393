import { useCallback, useEffect, useRef, useState } from 'react';
import useBff from '../bff/use-bff';
import { isUnauthorized } from '../bff/worlds';
import useComponentVisibility from './use-component-visibility';
import { usePageVisibility } from './use-page-visibility';

export const useWorldState = <Element extends HTMLElement>(
  worldName: string,
  transientStateRefreshIntervalMs: number = 10000
) => {
  const bff = useBff();
  const [isVisibilityRefVisible, visibilityRef] = useComponentVisibility<Element>();
  const isPageVisible = usePageVisibility();
  const lastRefreshedRef = useRef(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [worldState, setWorldState] = useState('...');
  const [ip, setIp] = useState<string>();

  const refreshErrorCountRef = useRef(0);
  const timerRef = useRef<any>(undefined);

  const refreshState = useCallback(() => {
    if (isRefreshing || !isPageVisible || !isVisibilityRefVisible) return;

    setIsRefreshing(true);
    bff
      .getWorld(worldName, { state: true })
      .then((res) => {
        refreshErrorCountRef.current = 0;
        setWorldState(res.data.state!);
        setIp(res.data.ip);
        lastRefreshedRef.current = Date.now();

        if (!['STOPPED', 'RUNNING'].find((s) => s === res.data.state!)) {
          timerRef.current = setTimeout(() => {
            refreshState();
          }, transientStateRefreshIntervalMs);
        }
      })
      .catch((err) => {
        // Do not retry if it is unauthorized.
        if (isUnauthorized(err)) return;

        timerRef.current = setTimeout(() => {
          if (refreshErrorCountRef.current < 12) {
            refreshErrorCountRef.current = refreshErrorCountRef.current + 1;
            refreshState();
          }
        }, 5000);
      })
      .finally(() => {
        setIsRefreshing(false);
      });
  }, [bff, isPageVisible, isRefreshing, isVisibilityRefVisible, transientStateRefreshIntervalMs, worldName]);

  useEffect(() => {
    const recentlyRefreshed = Date.now() - lastRefreshedRef.current < 10000; /* milliseconds */
    if (isVisibilityRefVisible && isPageVisible && !recentlyRefreshed) {
      refreshState();
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isVisibilityRefVisible, isPageVisible, refreshState]);

  return {
    worldState,
    ip,
    visibilityRef,
    isVisibilityRefVisible,
    refreshState,
    setWorldState,
  };
};
