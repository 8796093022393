import Form from 'react-bootstrap/Form';

type Props = {
  options: string[];
  value: string;
  onChange: (newValue: string) => void;
  selectElementId?: string;
};

const DropDown = ({ options, value, onChange, selectElementId }: Props) => {
  const optionElements = options.map((o) => (
    <option key={o} value={o}>
      {o}
    </option>
  ));

  const handleChange = (event: any) => {
    onChange(event.target.value);
  };

  return (
    <Form.Select
      size="sm"
      value={value}
      onChange={handleChange}
      id={selectElementId}
      className="ServerPropertiesEdit-input"
    >
      <>{optionElements}</>
    </Form.Select>
  );
};

export default DropDown;
