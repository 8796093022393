import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const [isFirstRender, setFirstRender] = useState(true);
  const { user, isSignedIn } = useAuth();

  useEffect(() => {
    if (isFirstRender && !user) {
      isSignedIn().then(() => {
        setFirstRender(false);
      });
    }
  }, [isFirstRender, isSignedIn, user]);

  if (isFirstRender && !user) {
    return <></>;
  }

  if (!user) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default RequireAuth;
