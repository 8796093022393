import axios from 'axios';
import { baseURL } from './config';

export type World = {
  name: string;
  ownerId?: string;
  edition?: string;
  version?: string;
  state?: string;
  ip?: string;
  serverProperties?: any;
  sharings?: [{ gamertag: string }];
};

export const isUnauthorized = (err: any) => {
  return err.response?.status === 401 || err.response?.status === 403;
};

export const listWorlds = async (options?: { sharedWithMe?: boolean }) => {
  const params = options
    ? {
        'shared-with-me': options?.sharedWithMe ? 'true' : '',
      }
    : undefined;
  return await axios
    .request<[World]>({
      baseURL,
      method: 'get',
      url: 'worlds',
      params,
      withCredentials: true,
    })
    .then((response) => {
      return response;
    });
};

export const getWorld = async (
  name: string,
  options?: {
    state?: boolean;
    serverProperties?: boolean;
    sharings?: boolean;
  }
) => {
  const params = {
    state: options?.state,
    'server-properties': options?.serverProperties,
    sharings: options?.sharings,
  };

  return await axios.request<World>({
    baseURL,
    method: 'get',
    url: `worlds/${name}`,
    params,
    withCredentials: true,
  });
};

export const startWorld = async (name: string) => {
  return await axios.request({
    baseURL,
    method: 'patch',
    url: `worlds/${name}`,
    data: {
      state: 'RUNNING',
    },
    withCredentials: true,
  });
};

export const stopWorld = async (name: string) => {
  return await axios.request({
    baseURL,
    method: 'patch',
    url: `worlds/${name}`,
    data: {
      state: 'STOPPED',
    },
    withCredentials: true,
  });
};

/**
 * @deprecated To be renamed to updateWorld
 */
export const saveServerOptions = async (
  name: string,
  options: { version?: string; serverProperties?: any; sharings?: { gamertags: string }[] }
) => {
  return await axios.request({
    baseURL,
    method: 'patch',
    url: `worlds/${name}`,
    data: options,
    withCredentials: true,
  });
};

export const createWorld = async (name: string, edition: string, version: string) => {
  return await axios.request({
    baseURL,
    method: 'post',
    url: `worlds/${name}`,
    data: {
      edition,
      version,
    },
    withCredentials: true,
  });
};

export const deleteWorld = async (name: string) => {
  return await axios.request({
    baseURL,
    method: 'delete',
    url: `worlds/${name}`,
    withCredentials: true,
  });
};

export const getUsersByGamertags = async (gamertags: string[]) => {
  return await axios.request({
    baseURL,
    method: 'get',
    url: `users`,
    params: { gamertags: gamertags.join(';') },
    withCredentials: true,
  });
};
