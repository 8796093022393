import './SharingEdit.css';
import { useEffect, useState } from 'react';
import { Button, ToastContainer } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import useBff from '../../bff/use-bff';
import ErrorToast from '../../components/ErrorToast';

type Props = {
  sharings: { gamertag: string }[];
  onChange: (sharings: { gamertag: string }[]) => void;
};

const SharingEdit = ({ sharings, onChange }: Props) => {
  const [newShareGamertag, setNewShareGamertag] = useState('');
  const [isCheckingExistenceOfGamertag, setCheckingExistenceOfGamertag] = useState(false);
  const [isAddButtonDisabled, setAddButtonDisabled] = useState(false);

  const [showInvalidGamertag, setShowInvalidGamertag] = useState(false);

  const bff = useBff();

  useEffect(() => {
    setAddButtonDisabled(newShareGamertag.trim().length <= 0 || isCheckingExistenceOfGamertag);
  }, [isCheckingExistenceOfGamertag, newShareGamertag]);

  const handleNewShareInputChange = (event: any) => {
    setNewShareGamertag(event.target.value.trim());
  };

  const handleAdd = async () => {
    if (sharings.find((s) => s.gamertag === newShareGamertag)) {
      // TODO: Show error
      return;
    }

    try {
      setCheckingExistenceOfGamertag(true);
      await bff.getUsersByGamertags([newShareGamertag]).then((res) => {
        if (res.status !== 200) {
          return;
        }
        const users = res.data;
        if (users.length <= 0) {
          setShowInvalidGamertag(true);
          return;
        }
        const newSharings = sharings.concat({ gamertag: users[0].gamertag });
        onChange(newSharings);
        setNewShareGamertag('');
      });
    } finally {
      // Cannot put this into the promise chain (i.e. the ".finally") as it messes up
      // the unit test with the "act" warning, even if I wait for all the visual side-effect.
      setCheckingExistenceOfGamertag(false);
    }
  };

  const handleRemove = (gamertagToRemove: string) => {
    const newSharings = sharings.filter((s) => s.gamertag !== gamertagToRemove);
    onChange(newSharings);
  };

  return (
    <>
      <ul>
        {sharings
          .sort((x, y) => {
            return x.gamertag.toLowerCase() < y.gamertag.toLowerCase() ? -1 : 1;
          })
          .map((s) => (
            <li key={s.gamertag}>
              <span>{s.gamertag}</span>
              &nbsp;
              <button
                onClick={() => {
                  handleRemove(s.gamertag);
                }}
                className="text-blue-500 underline"
              >
                remove
              </button>
            </li>
          ))}
        <li>
          <div className="SharingEdit-newshare-item">
            <Form.Label htmlFor="new-share" className="SharingEdit-newshare-label">
              Their gamer tag:
            </Form.Label>
            <div className="SharingEdit-newshare-input">
              <Form.Control
                id="new-share"
                type="input"
                onChange={handleNewShareInputChange}
                size="sm"
                value={newShareGamertag}
              />
            </div>
            <Button size="sm" disabled={isAddButtonDisabled} onClick={handleAdd}>
              Add
            </Button>
          </div>
        </li>
      </ul>

      <ToastContainer position="top-center" className="p-3 position-fixed">
        <ErrorToast
          show={showInvalidGamertag}
          onClose={() => {
            setShowInvalidGamertag(false);
          }}
          message="Gamer tag is invalid."
        />
      </ToastContainer>
    </>
  );
};

export default SharingEdit;
