import React from 'react';
import useBff from '../bff/use-bff';

type ReferenceData = {
  supportedEditions: { name: string; versions: { number: string }[] }[];
};

export interface ReferenceDataContextType {
  getReferenceData: () => Promise<ReferenceData>;
}

const ReferenceDataContext = React.createContext<ReferenceDataContextType>(null!);

export const ReferenceDataProvider = ({ children }: { children: React.ReactNode }) => {
  const bff = useBff();

  const getReferenceData = async () => {
    // Using global may not be the best way to store referenceData.
    const global = globalThis as any;
    if (global.refenceData) return global.refenceData;

    return await bff.getSupportedEditionsAndVersions().then((res) => {
      const newRefData = {
        supportedEditions: res.data.editions,
      };
      global.refenceData = newRefData;
      return newRefData;
    });
  };

  return <ReferenceDataContext.Provider value={{ getReferenceData }}>{children}</ReferenceDataContext.Provider>;
};

export const useReferenceData = () => {
  return React.useContext(ReferenceDataContext);
};
