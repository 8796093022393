import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import SignOutButton from './components/SignOutButton';
import { useAuth } from './hooks/use-auth';

function App() {
  const { isSignedIn } = useAuth();

  const [user, setUser] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      isSignedIn()
        .then((signedInUser) => {
          setUser(signedInUser);
          if (!signedInUser) {
            navigate('signin');
          } else {
            navigate('worlds');
          }
        })
        .catch(() => {
          setUser(undefined);
          navigate('signin');
        });
    }
  }, [isSignedIn, navigate, user]);

  const signOutSucceedHandler = () => {
    setUser(undefined);
  };

  return (
    <div className="App" data-testid="app">
      {/* <header className="App-header">here</header> */}
      <div>
        {!!user ? (
          <div>
            <div>{user.email}</div>
            <SignOutButton onSignOutSucceed={signOutSucceedHandler} />
          </div>
        ) : (
          <div>Redirecting you to sign in page.</div>
        )}
      </div>
    </div>
  );
}

export default App;
