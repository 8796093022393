import './ServerPropertiesEdit.css';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ServerPropertyDropDownEdit from './ServerPropertyDropDownEdit';

type Props = {
  serverEdition: string;
  serverProperties: any;
  onPropertyChange?: (name: string, value: any) => void;
};

function select(name: string, options: string[], defaultValue: string | undefined = undefined) {
  return { name, type: 'select', options, default: defaultValue ?? options[0] };
}

function boolean(name: string, defaultValue: boolean) {
  return { name, type: 'select', options: ['false', 'true'], default: defaultValue ? 'true' : 'false' };
}

// server.properties from here: https://minecraft.fandom.com/wiki/Server.properties
export const bedrockSupportedProperties = [
  select('gamemode', ['survival', 'creative', 'adventure']),
  boolean('force-gamemode', false),
  select('difficulty', ['peaceful', 'easy', 'normal', 'hard'], 'easy'),
  boolean('allow-cheats', false),
  // boolean('allow-list', false),
  select('level-type', ['DEFAULT', 'FLAT', 'LEGACY'], 'DEFAULT'),
  select('default-player-permission-level', ['visitor', 'member', 'operator'], 'member'),
];
export const javaSupportedProperties = [
  select('gamemode', ['survival', 'creative', 'adventure', 'spectator']),
  boolean('force-gamemode', false),
  select('difficulty', ['peaceful', 'easy', 'normal', 'hard'], 'easy'),
  boolean('enable-command-block', false),
  boolean('pvp', true),
  boolean('generate-structures', true),
  boolean('allow-flight', false),
  boolean('allow-nether', true),
  boolean('hardcore', false),
  boolean('broadcast-console-to-ops', true),
  boolean('spawn-npcs', true),
  boolean('spawn-animals', true),
  boolean('spawn-monsters', true),
  select(
    'level-type',
    [
      'minecraft:normal',
      'minecraft:flat',
      'minecraft:large_biomes',
      'minecraft:amplified',
      'minecraft:single_biome_surface',
    ],
    'minecraft:normal'
  ),
];

const ServerPropertiesEdit = ({ serverEdition, serverProperties, onPropertyChange }: Props) => {
  const supportedProperties = serverEdition === 'java' ? javaSupportedProperties : bedrockSupportedProperties;

  const handlePropertyChange = (propertyName: string, newValue: string) => {
    if (onPropertyChange) {
      onPropertyChange(propertyName, newValue);
    }
  };

  const [definedPropertiesNames, setDefinedPropertiesNames] = useState<string[]>([]);
  const [addablePropertiesNames, setAddablePropertiesNames] = useState<string[]>([]);
  const [newPropertyName, setNewPropertyName] = useState('');

  useEffect(() => {
    const definedNames = Object.keys(serverProperties ?? {});
    setDefinedPropertiesNames(definedNames);
    setAddablePropertiesNames(
      supportedProperties
        .map((sp) => sp.name)
        .filter((sp) => !definedNames.find((dn) => dn === sp))
        .sort()
    );
  }, [serverProperties, supportedProperties]);

  useEffect(() => {
    setNewPropertyName(addablePropertiesNames.length > 0 ? addablePropertiesNames[0] : '');
  }, [addablePropertiesNames]);

  const handleNewPropertySelectChange = (event: any) => {
    setNewPropertyName(event.target.value);
  };

  const handleAdd = () => {
    if (onPropertyChange) {
      onPropertyChange(newPropertyName, supportedProperties.find((sp) => sp.name === newPropertyName)?.default);
    }
  };

  if (!serverProperties) return <></>;

  const propertyEditComponents = supportedProperties
    .filter((p) => {
      return definedPropertiesNames.find((dpn) => dpn === p.name);
    })
    .sort((x, y) => (x.name < y.name ? -1 : 0))
    .map((p) => {
      return (
        <ServerPropertyDropDownEdit
          key={p.name}
          serverPropertyName={p.name}
          serverProperties={serverProperties}
          options={p.options}
          onChange={handlePropertyChange}
        />
      );
    });

  const addNewPropertyComponent =
    addablePropertiesNames.length > 0 ? (
      <div className="ServerPropertiesEdit-row">
        <label htmlFor="newPropertySelect" className="ServerPropertiesEdit-label">
          Add property:
        </label>
        <div className="ServerPropertiesEdit-input">
          <Form.Select
            size="sm"
            id="newPropertySelect"
            value={newPropertyName}
            onChange={handleNewPropertySelectChange}
          >
            {addablePropertiesNames.map((p) => {
              return (
                <option key={p} value={p}>
                  {p}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div className="ServerPropertiesEdit-action">
          <Button onClick={handleAdd} size="sm">
            Add
          </Button>
        </div>
      </div>
    ) : (
      <div>No more supported server property to add.</div>
    );

  return (
    <div id="server-properties-edit" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      {propertyEditComponents}
      {addNewPropertyComponent}
    </div>
  );
};

export default ServerPropertiesEdit;
