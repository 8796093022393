import './CreateWorld.css';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form, ToastContainer } from 'react-bootstrap';
import { loadEnvConfig } from '../../config';
import ErrorToast from '../../components/ErrorToast';
import useBff from '../../bff/use-bff';
import InfoToast from '../../components/InfoToast';
import toggles from '../../toggles';
import { useReferenceData } from '../../hooks/use-reference-data';

type Props = {
  onNewWorldCreated?: (name: string) => void;
};

const CreateWorld = ({ onNewWorldCreated }: Props) => {
  const [name, setName] = useState('');
  const { getReferenceData } = useReferenceData();
  const [supportedEditions, setSupportedEditions] = useState<any[]>([]);
  const [selectedEdition, setSelectedEdition] = useState<string>('');
  const [versions, setVersions] = useState<string[]>([]);
  const [selectedVersion, setSelectedVersion] = useState('');

  const [isCreating, setIsCreating] = useState(false);
  const [showCreateWorldError, setShowCreateWorldError] = useState(false);
  const defaultCreateErrorMessage = 'Unable to create the world.';
  const [createErrorMessage] = useState(defaultCreateErrorMessage);

  const [showWorldCreatedInfo, setShowWorldCreatedInfo] = useState(false);

  const bff = useBff();

  useEffect(() => {
    getReferenceData().then((refData) => {
      setSupportedEditions(refData.supportedEditions);
      if (refData.supportedEditions.length > 0) setSelectedEdition(refData.supportedEditions[0].name);
    });
  }, [getReferenceData]);

  useEffect(() => {
    const ed = supportedEditions.find((e) => e.name === selectedEdition);
    const versionsForSelectedEdition = ed ? ed.versions.map((v: any) => v.number) : [];
    setVersions(versionsForSelectedEdition);
    setSelectedVersion(versionsForSelectedEdition.length > 0 ? versionsForSelectedEdition[0] : '');
  }, [selectedEdition, supportedEditions]);

  const handleNameChanged = (event: any) => {
    const modified = (event.target.value as string)
      .toLowerCase()
      .replaceAll(/[^a-z0-9-]/g, '')
      .replace(/^-*/, ''); // disallow starting with dash
    setName(modified);
  };

  const handleEditionChanged = (event: any) => {
    setSelectedEdition(event.target.value);
  };
  const handleVersionChanged = (event: any) => {
    setSelectedVersion(event.target.value);
  };
  const handleCreateClicked = async () => {
    setIsCreating(true);
    bff
      .createWorld(name, selectedEdition, selectedVersion)
      .then((res) => {
        if (res.status === 200) {
          setName('');
          setShowWorldCreatedInfo(true);
          if (onNewWorldCreated) {
            onNewWorldCreated(name);
          }
        } else {
          setShowCreateWorldError(true);
        }
      })
      .catch(() => {
        setShowCreateWorldError(true);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return (
    <div className="flex flex-col mb-1 border border-gray-300">
      <div className="flex bg-cyan-100 p-1 align-baseline">Create a new world</div>
      <div className="p-1">
        <div className="CreateWorld-detail-row">
          <label htmlFor="nameInput" style={{ display: 'none' }}>
            Name:
          </label>
          <div className="CreateWorld-input">
            <Form.Control type="input" id="nameInput" value={name} onChange={handleNameChanged} size="sm" />
          </div>
          <div className="CreateWorld-domain">.{loadEnvConfig().domain}</div>
        </div>
        <div className="CreateWorld-detail-row">
          <label htmlFor="editionSelect" className="CreateWorld-label">
            Edition:
          </label>
          <div className="CreateWorld-edition-select">
            <Form.Select id="editionSelect" value={selectedEdition} onChange={handleEditionChanged} size="sm">
              {supportedEditions.map((ed) => (
                <option key={ed.name}>{ed.name}</option>
              ))}
            </Form.Select>
            <label htmlFor="versionSelect" style={{ display: 'none' }}>
              Version:
            </label>
            <Form.Select
              id="versionSelect"
              value={selectedVersion}
              onChange={handleVersionChanged}
              size="sm"
              style={{ display: toggles.dynamicVersions ? 'inherit' : 'none' }}
            >
              {versions.map((v) => (
                <option key={v}>{v}</option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="flex p-1 bg-stone-200 gap-1">
        <Button onClick={handleCreateClicked} disabled={name.trim() === '' || isCreating}>
          Create
        </Button>
      </div>

      <ToastContainer position="top-center" className="p-3 position-fixed">
        <ErrorToast
          show={showCreateWorldError}
          onClose={() => {
            setShowCreateWorldError(false);
          }}
          message={createErrorMessage}
          autohide={true}
        />
        <InfoToast
          show={showWorldCreatedInfo}
          onClose={() => {
            setShowWorldCreatedInfo(false);
          }}
          message="World created."
          autohide={true}
        />
      </ToastContainer>
    </div>
  );
};

export default CreateWorld;
