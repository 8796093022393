import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SignInPage from './routes/SignInPage/SignInPage';
import { AuthProvider } from './hooks/use-auth';
import WorldsPage from './routes/WorldsPage/WorldsPage';
import RequireAuth from './components/RequireAuth';
import WorldEditPage from './routes/WorldsPage/WorldEditPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import ProfilePage from './routes/ProfilePage/ProfilePage';
import toggles from './toggles';
import { ReferenceDataProvider } from './hooks/use-reference-data';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <App />
      </RequireAuth>
    ),
    children: [],
  },
  {
    path: '/signin',
    element: <SignInPage />,
  },
  {
    path: '/worlds',
    element: (
      <RequireAuth>
        <ReferenceDataProvider>
          <WorldsPage />
        </ReferenceDataProvider>
      </RequireAuth>
    ),
  },
  {
    path: '/profile',
    element: <RequireAuth>{toggles.profilePage ? <ProfilePage /> : <div>Profile page coming soon!</div>}</RequireAuth>,
  },
  {
    path: '/worlds/:worldName',
    element: (
      <RequireAuth>
        <ReferenceDataProvider>
          <WorldEditPage />
        </ReferenceDataProvider>
      </RequireAuth>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // Disable Strict Mode. See https://www.techiediaries.com/react-18-useeffect/
  // <React.StrictMode>
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
