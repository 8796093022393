import './WorldEditPage.css';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useNavigate, useParams } from 'react-router-dom';
import useBff from '../../bff/use-bff';
import { World } from '../../bff/worlds';
import ErrorToast from '../../components/ErrorToast';
import { useReferenceData } from '../../hooks/use-reference-data';
import toggles from '../../toggles';
import ServerPropertiesEdit from './ServerPropertiesEdit';
import SharingEdit from './SharingEdit';
import { useWorldState } from '../../hooks/use-world-state';
import WorldStateIndicator from './WorldStateIndicator';

const WorldEditPage = () => {
  const showSharings = toggles.worldsSharing;

  const { worldName } = useParams();
  const [world, setWorld] = useState<World>();
  const [versions, setVersions] = useState<string[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<string>();
  const [serverProperties, setServerProperties] = useState<any>();
  const [sharings, setSharings] = useState<any[]>([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showSaveError, setShowSaveError] = useState(false);
  const [showDeleteError, setShowDeleteError] = useState(false);

  const navigate = useNavigate();
  const { deleteWorld, getWorld, saveServerOptions } = useBff();

  const { getReferenceData } = useReferenceData();

  const { worldState, visibilityRef, refreshState } = useWorldState<HTMLLabelElement>(worldName!);

  useEffect(() => {
    setDisableSaveButton(true);
    getWorld(worldName!, { state: true, serverProperties: true, sharings: true }).then((res) => {
      if (res.status === 200) {
        setWorld(res.data);
        setSelectedVersion(res.data.version);
        setServerProperties(res.data?.serverProperties);
        setSharings(res.data?.sharings ?? []);
        setDisableSaveButton(false);
        return res.data;
      } else {
        navigate('/worlds');
      }
    });
  }, [getWorld, navigate, worldName]);

  useEffect(() => {
    getReferenceData().then((refData) => {
      const editionObj = refData.supportedEditions.find((e) => e.name === world?.edition);
      setVersions(editionObj?.versions.map((v) => v.number) ?? []);
    });
  }, [getReferenceData, world?.edition]);

  const handlePropertyChange = (name: string, value: any) => {
    const newServerProperties = {
      ...serverProperties,
      [name]: value,
    };
    if (!value) delete newServerProperties[name];
    setServerProperties(newServerProperties);
  };

  const handleSharingsChange = (newSharings: { gamertag: string }[]) => {
    setSharings(newSharings);
  };

  const handleSaveButtonClicked = async () => {
    setDisableSaveButton(true);
    saveServerOptions(worldName!, { version: selectedVersion, serverProperties, sharings })
      .then((result) => {
        if (result.status === 200) {
          navigate('/worlds');
        } else {
          setShowSaveError(true);
        }
      })
      .catch(() => {
        setShowSaveError(true);
      });
    setDisableSaveButton(false);
  };

  const handleDeleteButtonClicked = () => {
    setShowConfirmDelete(true);
  };

  const handlePermanentlyDelete = async () => {
    deleteWorld(worldName!)
      .then((result) => {
        if (result.status === 200) {
          navigate('/worlds');
        } else {
          setShowDeleteError(true);
        }
      })
      .catch((e) => {
        setShowDeleteError(true);
      });
  };

  const handleMouseEnterState = () => {
    if (['RUNNING', 'STOPPED'].find((steadyState) => worldState === steadyState)) {
      refreshState();
    }
  };

  return (
    <div>
      <h2>General information:</h2>
      <div className="WorldEdit-general-information-section">
        <div className="WorldEdit-general-information-row">
          <label className="WorldEdit-general-information-label">World:</label>
          <div>{worldName}</div>
        </div>
        <div className="WorldEdit-general-information-row">
          <label className="WorldEdit-general-information-label">Edition:</label>
          <div>{world?.edition}</div>
        </div>
        <div className="WorldEdit-general-information-row">
          <label htmlFor="selectedVersion" className="WorldEdit-general-information-label">
            Version:
          </label>
          <Form.Select
            id="selectedVersion"
            size="sm"
            style={{ width: '120px' }}
            value={selectedVersion}
            onChange={(event: any) => {
              setSelectedVersion(event.target.value);
            }}
            disabled={world?.state !== 'STOPPED'}
          >
            {versions.map((v) => (
              <option key={v}>{v}</option>
            ))}
          </Form.Select>
        </div>
        <div className="WorldEdit-general-information-row">
          <label className="WorldEdit-general-information-label" ref={visibilityRef}>
            State:
          </label>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <span style={{ flex: '0 1 auto', marginRight: '5px' }}>
              <WorldStateIndicator state={worldState} />
            </span>
            <span onMouseEnter={handleMouseEnterState}>{worldState}</span>
          </div>
        </div>
      </div>
      <div>&nbsp;</div>

      <h2>Server Properties:</h2>
      <div>Customise your server.</div>
      <ServerPropertiesEdit
        serverEdition={world?.edition!}
        serverProperties={serverProperties}
        onPropertyChange={handlePropertyChange}
      />

      {showSharings ? (
        <div>
          <h2>Sharing</h2>
          <div>Share this world with others and allow them to start it.</div>
          <SharingEdit sharings={sharings} onChange={handleSharingsChange} />
        </div>
      ) : undefined}

      <div>&nbsp;</div>
      <div>
        <Button variant="secondary" onClick={() => navigate('/worlds')}>
          Cancel
        </Button>
        <Button onClick={handleSaveButtonClicked} disabled={disableSaveButton}>
          Save
        </Button>
      </div>
      <div>&nbsp;</div>
      <div>
        <Button onClick={handleDeleteButtonClicked} disabled={world?.state !== 'STOPPED'}>
          DELETE
        </Button>
      </div>

      <Modal show={showConfirmDelete}>
        <Modal.Header>
          <Modal.Title>Delete world '{worldName}'?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will permanently delete the world, and it is not reversible. Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handlePermanentlyDelete}>PERMANENTLY DELETE</Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer position="top-center" className="p-3 position-fixed">
        <ErrorToast
          show={showSaveError}
          onClose={() => {
            setShowSaveError(false);
          }}
          message="Unable to save your changes."
        />
        <ErrorToast
          show={showDeleteError}
          onClose={() => {
            setShowDeleteError(false);
          }}
          message="Unable to delete this world."
        />
      </ToastContainer>
    </div>
  );
};

export default WorldEditPage;
