import React, { useState } from 'react';
import { isLoggedIn, login, logout, UserAccount } from '../bff/auth';
import { loadEnvConfig } from '../config';

export interface AuthContextType {
  user: UserAccount;
  isSignedIn: () => Promise<any>;
  isCheckingSignedIn: boolean;
  signIn: (username: string, password: string) => Promise<any>;
  signOut: () => Promise<void>;
}

let AuthContext = React.createContext<AuthContextType>(null!);

const envConfig = loadEnvConfig();
export const baseURL = `https://bff.${envConfig.userWebapp.host}.${envConfig.domain}/`;

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<any>();
  const [isCheckingSignedIn, setIsCheckingSignedIn] = useState(false);

  const isSignedIn = async () => {
    setIsCheckingSignedIn(true);
    return isLoggedIn()
      .then((u) => {
        setUser(u);
        return u;
      })
      .finally(() => {
        setIsCheckingSignedIn(false);
      });
  };

  const signIn = async (username: string, password: string) => {
    return login(username, password).then((u) => {
      setUser(u);
      return u;
    });
  };

  // TODO: Add optional global signout
  const signOut = async () => {
    return logout().then(() => {
      setUser(undefined);
    });
  };

  return (
    <AuthContext.Provider
      value={
        {
          user,
          isSignedIn,
          isCheckingSignedIn,
          signIn,
          signOut,
        } as AuthContextType
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
