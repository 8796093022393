import Button from 'react-bootstrap/Button';
import { useAuth } from '../hooks/use-auth';

type Props = {
  buttonText?: string;
  onSignOutInitiated?: (promise: Promise<'succeeded' | 'failed'>) => void;
  onSignOutSucceed?: () => void;
  onSignOutFailed?: () => void;
};

const SignOutButton = ({ buttonText = 'Sign out', onSignOutInitiated, onSignOutSucceed, onSignOutFailed }: Props) => {
  const { signOut } = useAuth();

  const signOutHandler = () => {
    const signOutPromise = new Promise<'succeeded' | 'failed'>((resolve) => {
      try {
        signOut()
          .then(() => {
            resolve('succeeded');
            if (onSignOutSucceed) {
              onSignOutSucceed();
            }
          })
          .catch((err) => {
            resolve('failed');
            if (onSignOutFailed) {
              onSignOutFailed();
            }
          });
      } catch {
        resolve('failed');
        if (onSignOutFailed) {
          onSignOutFailed();
        }
      }
    });

    if (onSignOutInitiated) {
      onSignOutInitiated(signOutPromise);
    }
  };

  return <Button onClick={signOutHandler}>{buttonText}</Button>;
};

export default SignOutButton;
