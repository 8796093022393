import './SigninPage.css';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

const SignInPage = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { signIn } = useAuth();
  const [user, setUser] = useState<unknown>();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const navigate = useNavigate();

  const handleEmailOnChange = (event: any) => {
    setEmail(event.currentTarget.value);
  };

  const handlePasswordOnChange = (event: any) => {
    setPassword(event.currentTarget.value);
  };

  const handleSignIn = async () => {
    setIsSigningIn(true);
    signIn(email!, password!)
      .then((u) => {
        setUser(u);
      })
      .finally(() => {
        setIsSigningIn(false);
      });
  };

  useEffect(() => {
    if (user) {
      navigate('/worlds');
    }
  });

  const inputs = isSigningIn ? (
    <img alt="spinner" src="assets/spinner.svg" />
  ) : (
    <>
      <div className="SignInPage-input-row">
        <Form.Label className="SignInPage-input-label" htmlFor="email">
          Email:
        </Form.Label>
        <Form.Control
          className="SignInPage-input-control"
          id="email"
          value={email}
          onChange={handleEmailOnChange}
        ></Form.Control>
      </div>
      <div className="SignInPage-input-row">
        <Form.Label className="SignInPage-input-label" htmlFor="password">
          Password:
        </Form.Label>
        <Form.Control
          className="SignInPage-input-control"
          id="password"
          value={password}
          onChange={handlePasswordOnChange}
          type="password"
        />
      </div>
    </>
  );

  return (
    <div>
      <h1>Sign in</h1>
      <div className="SignInPage-container">
        {inputs}
        <div className="SignInPage-action-row">
          <Button onClick={handleSignIn} disabled={isSigningIn}>
            Sign in
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
