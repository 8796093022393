import Toast from 'react-bootstrap/Toast';

type Props = {
  show: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  children?: React.ReactNode;
  autohide?: boolean;
};

const InfoToast = ({ show, onClose, autohide = true, title = 'Information', message, children }: Props) => {
  return (
    <Toast show={show} autohide={autohide} onClose={onClose} delay={5000} bg="success">
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{children ? children : message}</Toast.Body>
    </Toast>
  );
};

export default InfoToast;
