import Toast from 'react-bootstrap/Toast';

type Props = {
  show: boolean;
  onClose: () => void;
  message?: string;
  children?: React.ReactNode;
  autohide?: boolean;
};

const ErrorToast = ({ show, onClose, autohide = true, message, children }: Props) => {
  return (
    <Toast show={show} autohide={autohide} onClose={onClose} delay={5000} bg="danger">
      <Toast.Header>
        <strong className="me-auto">Error</strong>
      </Toast.Header>
      <Toast.Body>{children ? children : message}</Toast.Body>
    </Toast>
  );
};

export default ErrorToast;
