import * as envConfig from './env-config.json';

export const loadEnvConfig = () => {
  let config;
  if (process.env.REACT_APP_ENV_CONFIG_JSON) {
    config = JSON.parse(process.env.REACT_APP_ENV_CONFIG_JSON!) as any;
  } else {
    config = envConfig as any;
  }

  // Only a subset of the config that is really needed is made available on the client side.
  // This is done in the prebuild/prestart/pretest scripts.
  return config;
};
